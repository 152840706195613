<template>
  <div>
    <div class="flix-form-group">
      <form @submit.prevent="callback(newSearch)">
      <div class="flix-form-group">
        <input v-model="newSearch" type="text" class="flix-form-control" :placeholder="$t('message.search') + ': '  + $t('message.first_name') + ', ' + $t('message.last_name') + ', ' + $t('message.email') + ' ...'"/>
      </div>
      <button type="submit" class="flix-btn flix-btn-default flix-btn-xs" ><flixIcon id="search" /> {{ $t('message.search') }}</button>
      <a href="#" class="flix-btn flix-btn-default flix-btn-xs" v-if="search" @click.prevent="removeSearch()"><flixIcon id="remove" /></a>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    search: String,
    callback: Function
  },
  data () {
    return {
      newSearch: this.search
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    removeSearch () {
      this.newSearch = ''
      this.callback(this.newSearch)
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
